// page ready
$(document).ready(function () {

});


// system Functions
function sw(title, content, type) {
    var finalType = 'warning';
    if (type == 's')
        finalType = 'success';
    if (type == 'e')
        finalType = 'error';
    if (type == 'w')
        finalType = 'warning';
    if (type == 'w3')
        finalType = 'warning';

    swal({title: title, text: content, type: finalType, confirmButtonText: "OK"});
}

function bc() {
    $('body').append('<div id="blackCome"><h1>Please Hold..</h1><img src="assets/img/loader.gif"></div>');
}

function bg() {
    $('#blackCome').remove();
}

function bm_not_empty(val) {
    return !(val === '' || val === ' ' || val === '  ' || val === '   ' || val === '    ');
}